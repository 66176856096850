import React from "react";

export const postcodeRegex = /^([A-Z]{1,2}[A-Z\d]{1,2}) ?(\d[A-Z]{2})$/i;
export const accountIdRegex = /^\d{1,10}$/i;

/**
 * Attempts to format the passed UK postcode. If unsuccessful, it returns the original string.
 * @param postcode
 */

export const formatPostcode = (postcode: string) => {
  const pcTrimmed = postcode?.replace(/\s/g, "").toUpperCase();
  const parts = postcodeRegex.exec(pcTrimmed);
  const formatted = parts?.slice(1).join(" ");
  return formatted || postcode;
};

export const sanitiseData = (data: any) => {
  return {
    accountId: data.accountId?.trim(),
    postcode: formatPostcode(data.postcode),
    disclaimer: data.disclaimer,
  };
};

export const wrapper = (msg: String) => {
  return <div className="thank-footer">{msg}</div>;
};
