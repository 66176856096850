import React, { useEffect, useRef } from "react";
import { useMachine } from "@xstate/react";
import { parse } from "query-string";
import get from "lodash/get";
import "../form.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { applicationMachine, WhdFormState } from "./whd-form-state";

import {
  DumbPageExitAccountNotOnSupply,
  DumbPageExitNonScottishPostcode,
  DumbPageExitTokenNotValid,
  DumbPageSuccess,
} from "./dumb-pages";
import { FormAccount } from "./formComponents/Pg1AccountDetails";
import { FormCustomerDetails } from "./formComponents/Pg2CustomerDetails";
import { FormDeclaration } from "./formComponents/Pg9Declarations";
import { MainAccountHolderDetails } from "./formComponents/Pg8OnClaimantBehalf";
import { CustomExitErrorMessage } from "../commonComponents/exitErrorMsg";
import { StateAction } from "../util/stateUtil";
import { main } from "../commonComponents/main";
import { FormChildBenefits } from "./formComponents/Pg3ChildBenefits";
import { FormIncomeWork } from "./formComponents/Pg4IncomeWork";
import { FormDisabilityCare } from "./formComponents/Pg5DisabilityCare";
import { FormHousing } from "./formComponents/Pg6Housing";
import { FormPension } from "./formComponents/Pg7Pension";
import { DumbPageSomethingWentWrong } from "../commonComponents/CommonDumbPages";

const Main = ({ state, stateService }: any) => {
  switch (state.value) {
    case WhdFormState.GATHER_ACCOUNT_DETAILS:
      return <FormAccount stateService={stateService} />;
    case WhdFormState.GATHER_PERSONAL_DETAILS:
      return <FormCustomerDetails stateService={stateService} />;
    case WhdFormState.CHILD_BENEFITS_DETAILS:
      return <FormChildBenefits stateService={stateService} />;
    case WhdFormState.INCOME_WORK_DETAILS:
      return <FormIncomeWork stateService={stateService} />;
    case WhdFormState.DISABILITY_CARE_DETAILS:
      return <FormDisabilityCare stateService={stateService} />;
    case WhdFormState.HOUSING_DETAILS:
      return <FormHousing stateService={stateService} />;
    case WhdFormState.PENSION_DETAILS:
      return <FormPension stateService={stateService} />;
    case WhdFormState.GATHER_MAIN_ACCOUNT_HOLDER_DETAILS:
      return <MainAccountHolderDetails stateService={stateService} />;
    case WhdFormState.FINAL_DECLARATION:
      return <FormDeclaration stateService={stateService} />;
    case WhdFormState.END_SUCCESS:
      return <DumbPageSuccess />;
    case WhdFormState.EXIT_ACCOUNT_NOT_ON_SUPPLY:
      return <DumbPageExitAccountNotOnSupply />;
    case WhdFormState.EXIT_NON_SCOTTISH_POSTCODE:
      return <DumbPageExitNonScottishPostcode />;
    case WhdFormState.EXIT_UNEXPECTED:
      return <DumbPageSomethingWentWrong />;
    case WhdFormState.EXIT_TOKEN_NOT_VALID:
      return <DumbPageExitTokenNotValid />;
    case WhdFormState.EXIT_ERROR_MESSAGE:
      return <CustomExitErrorMessage stateService={stateService} />;
    default:
      return (
        <div className="spinner-grow" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
  }
};

const getParam = (param: string): string => {
  const urlSearchParams = window && parse(get(window, "location.search", {}));
  return get(urlSearchParams, param) as string;
};

export const OpenApp = () => {
  const [state, send, stateService] = useMachine(applicationMachine);
  const mainRef: React.MutableRefObject<null> = useRef(null);

  useEffect(() => {
    if (!(mainRef && mainRef.current)) {
      return;
    }
    if (!(window && window.parent)) {
      return;
    }

    const timeout = setTimeout(() => {
      const parentUrl = getParam("parent-url");
      const clientHeight = (mainRef.current as any).clientHeight;

      window.parent.postMessage(
        {
          func: "scrollTop",
          message: clientHeight,
        },
        parentUrl,
      );
    }, 10);

    return () => clearTimeout(timeout);
  }, [state.value]);

  useEffect(() => {
    const stage = getParam("journey-entry");

    if (
      stage ===
      WhdFormState.GATHER_PERSONAL_DETAILS.toLowerCase().replace(/_/g, "-")
    ) {
      send(StateAction.JUMP);
    }
  }, [send]);

  const openMainDiv = (
    <div>
      <Main state={state} stateService={stateService} />
    </div>
  );

  return main(openMainDiv, mainRef, "Apply for the scheme 2024-25");
};
