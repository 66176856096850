import React from "react";
import uniqueId from "lodash/uniqueId";
import camelCase from "lodash/camelCase";

export const InputCheckBox = ({
  register,
  required,
  name,
  label,
  question,
  details,
  statement,
  errors,
  errorMessage,
  hint,
  onChange,
  isChecked,
}: {
  register: any;
  required?: boolean;
  name?: string;
  label: string;
  question?: React.ReactFragment;
  details?: React.ReactFragment;
  statement: React.ReactFragment;
  errorMessage?: string;
  errors?: any;
  hint?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked?: boolean;
}) => {
  const _uniqueId = uniqueId();
  const camelName = camelCase(name || label);
  const _isChecked = isChecked || false;

  return (
    <div className="form-group">
      {question ? <b>{question}</b> : ""}
      {details ? <p>{details}</p> : ""}

      <div className="form-check">
        <input
          className={`form-check-input${
            errors && errors[camelName] ? " is-invalid" : ""
          }`}
          ref={register({ required })}
          type="checkbox"
          id={_uniqueId}
          name={camelName}
          aria-describedby={`${_uniqueId}-feedback`}
          defaultChecked={_isChecked}
          onChange={onChange}
        />
        <label className="form-check-label" htmlFor={_uniqueId}>
          <div>{statement}</div>
        </label>

        {hint && errors && !errors[camelName] && (
          <small id={`${_uniqueId}-feedback`} className="form-text">
            {hint}
          </small>
        )}
        {errors && errors[camelName] && (
          <div id={`${_uniqueId}-feedback`} className="invalid-feedback">
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};
