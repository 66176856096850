import { useService } from "@xstate/react";
import React from "react";

export const CustomExitErrorMessage = ({
  stateService,
}: {
  stateService: any;
}) => {
  const [state]: any[] = useService(stateService);
  const message = state.context.message;

  return <p>{message}</p>;
};
