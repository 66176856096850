import React, { ChangeEvent } from "react";
import camelCase from "lodash/camelCase";
import uniqueId from "lodash/uniqueId";

export const InputText = ({
  register,
  name,
  label,
  errors,
  errorMessage,
  required,
  disabled,
  hint,
  pattern,
  placeholder,
  width,
  defaultValue,
  onChange,
}: {
  register: any;
  name?: string;
  label: string;
  errors?: any;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
  hint?: string;
  pattern?: RegExp;
  placeholder?: string;
  width?: number;
  defaultValue?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const _uniqueId = uniqueId();
  const camelName = camelCase(name || label);
  const _width = width || 100;
  const _defaultValue = defaultValue || "";

  return (
    <div className="form-group">
      <b>
        <label htmlFor={_uniqueId} className="brand-midnight">
          {label}
        </label>
      </b>
      <input
        className={`form-control-text-custom${
          errors && errors[camelName] ? " is-invalid" : ""
        }`}
        disabled={disabled}
        id={_uniqueId}
        name={camelName}
        ref={register({ required, pattern })}
        aria-describedby={`${_uniqueId}-feedback`}
        placeholder={placeholder}
        style={{ width: `${_width}%` }}
        defaultValue={_defaultValue}
        onChange={onChange}
      />

      {hint && errors && !errors[camelName] && (
        <small id={`${_uniqueId}-feedback`} className="brand-midnight">
          {hint}
        </small>
      )}
      {errors && errors[camelName] && (
        <div id={`${_uniqueId}-feedback`} className="invalid-feedback">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export const DoBInputText = ({
  register,
  name,
  label,
  errors,
  errorMessage,
  required,
  disabled,
  pattern,
}: {
  register: any;
  name?: string;
  label: string;
  errors: any;
  errorMessage: string;
  required?: boolean;
  disabled?: boolean;
  pattern?: RegExp;
}) => {
  const _uniqueId = uniqueId();
  const camelName = camelCase(name || label);
  const current = new Date().toISOString().split("T")[0];

  return (
    <div className="form-group">
      <label htmlFor={_uniqueId}>{label}</label>
      <input
        className={`form-control-text-custom${
          errors[camelName] ? " is-invalid" : ""
        }`}
        disabled={disabled}
        type="date"
        max={current}
        id={_uniqueId}
        name={camelName}
        ref={register({ required, pattern })}
        aria-describedby={`${_uniqueId}-feedback`}
      />

      {errors[camelName] && (
        <div id={`${_uniqueId}-feedback`} className="invalid-feedback">
          {errorMessage}
        </div>
      )}
    </div>
  );
};
