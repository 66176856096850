import React from "react";

const { REACT_APP_URL_WARM_HOME_DISCOUNT_SCHEME_INFO } = process.env;

export const DumbPageSuccess = () => (
  <div className="thank-footer">
    <p>
      <b>
        Thank you for registering your interest, applications will open in
        Winter 2024. We’ll be in touch as soon as you can apply for the 2024/25
        Warm Home Discount.
      </b>
    </p>
    <div className="thank-footer-bottom">
      <p>
        Thanks,
        <br /> OVO Energy
      </p>
    </div>
  </div>
);

export const DumbPageExitInvalidAccount = () => (
  <div className="thank-footer">
    <p>
      Sorry - sadly we're not supplying your electricity, which means you will
      not be able to register your interest for the Warm Home Discount with us.
      Please refer your Warm Home Discount query to your electricity supplier.
    </p>
  </div>
);

export const DumbPagePostcodeMismatch = () => (
  <div className="thank-footer">
    <p>
      The account number or postcode you have submitted is incorrect. Please
      double check these details and try again.
    </p>
  </div>
);

export const DumbPageAccountNotOnSupply = () => (
  <div className="thank-footer">
    <p>
      Sorry – sadly we're not supplying your electricity, which means you can't
      apply for a Warm Home Discount with us.
    </p>
  </div>
);

export const DumbPageNonScottishPostcode = () => (
  <div className="thank-footer">
    <p>
      Applications for the Warm Home Discount are only open to customers in
      Scotland. Eligible customers in England and Wales will be paid
      automatically. To find out more, head{" "}
      <a href={REACT_APP_URL_WARM_HOME_DISCOUNT_SCHEME_INFO} target="_top">
        here
      </a>
      .
    </p>
  </div>
);

export const DumbPageExitDuplicate = () => (
  <div className="thank-footer">
    <p>
      You've already registered your interest and are on our Warm Home Discount
      communication list. We’ll be in touch as soon as you can apply for the
      2024/2025 Warm Home Discount.
    </p>
  </div>
);
