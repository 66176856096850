import React from "react";

const { REACT_APP_URL_WARM_HOME_DISCOUNT_SCHEME_INFO } = process.env;

export const DumbPageSomethingWentWrong = () => (
  <div className="thank-footer">
    <b>Oops, something has gone wrong. Please try again later.</b> <br />
    More information about who is eligible for the Warm Home Discount can be
    found
    <a href={REACT_APP_URL_WARM_HOME_DISCOUNT_SCHEME_INFO} target="_top">
      {" "}
      here.
    </a>
  </div>
);
