import { assign, Machine } from "xstate";
import {
  EventActions,
  isTruthy,
  makeAssignObject,
  StateAction,
} from "../util/stateUtil";
import { errorMsg, returnedError } from "../util/reusableFunctions/util";

const REGISTRATION_STATE = "registrationState";
const POST_ACCOUNT_DETAILS = "postAccountDetails";
const POST_ALL_DETAILS = "postAllDetails";
const {
  REACT_APP_API_URL_PRE_REG_ON_SUPPLY,
  REACT_APP_API_URL_PRE_REG_APPLICATION,
} = process.env;

export const WhdPreRegState = {
  GATHER_ACCOUNT_DETAILS: "GATHER_ACCOUNT_DETAILS",
  VERIFY_ACCOUNT_DETAILS: "VERIFY_ACCOUNT_DETAILS",
  GATHER_PERSONAL_DETAILS: "GATHER_PERSONAL_DETAILS",
  VERIFY_ALL_DETAILS: "VERIFY_ALL_DETAILS",
  END_SUCCESS: "END_SUCCESS",
  EXIT_DUPLICATE: "EXIT_DUPLICATE",
  EXIT_POSTCODE_MISMATCH: "EXIT_POSTCODE_MISMATCH",
  EXIT_ACCOUNT_NOT_ON_SUPPLY: "EXIT_ACCOUNT_NOT_ON_SUPPLY",
  EXIT_NON_SCOTTISH_POSTCODE: "EXIT_NON_SCOTTISH_POSTCODE",
  EXIT_ERROR_MESSAGE: "EXIT_ERROR_MESSAGE",
  EXIT_NOT_FOUND: "EXIT_NOT_FOUND",
  EXIT_NOT_ON_SUPPLY: "EXIT_NOT_ON_SUPPLY",
  EXIT_MISMATCH: "EXIT_MISMATCH",
};

const postOnSupplyCheck = (context, callback) => {
  const unexpected = () => {
    callback({ type: "UNEXPECTED" });
  };
  fetch(REACT_APP_API_URL_PRE_REG_ON_SUPPLY, {
    method: "POST",
    body: JSON.stringify({
      accountId: context.accountId.trim(),
      postcode: context.postcode,
    }),
  }).then((res) => {
    switch (res.status) {
      case 200:
        return callback({ type: "OK" });
      case 230:
        return callback({ type: "NOT_ON_SUPPLY" });
      case 231:
        return callback({ type: "MISMATCH" });
      case 240:
        return callback({ type: "NON_SCOTTISH_POSTCODE" });
      case 404:
        return callback({ type: "NOT_FOUND" });
      case 409:
        return callback({ type: "DUPLICATE" });
      default:
        return returnedError(res, callback, unexpected);
    }
  });
};

const postApplicationDetails = (context, callback) =>
  fetch(REACT_APP_API_URL_PRE_REG_APPLICATION, {
    method: "POST",
    body: JSON.stringify({
      accountId: context.accountId.trim(),
      name: `${context.lastName} ${context.firstName}`,
      address1: context.address1,
      address2: context.address2,
      postcode: context.postcode,
      dualFuel: isTruthy(context.dualFuel),
      guaranteedCredit: context.guaranteedCredit
        ? isTruthy(context.guaranteedCredit)
        : undefined,
      smartMeterInterest: isTruthy(context.smartMeterInterest),
      declaration: isTruthy(context.declaration),
    }),
  }).then((res) => {
    switch (res.status) {
      case 201:
        return callback({ type: "CREATED" });
      case 230:
        return callback({ type: "NOT_ON_SUPPLY" });
      case 231:
        return callback({ type: "MISMATCH" });
      case 240:
        return callback({ type: "NON_SCOTTISH_POSTCODE" });
      case 404:
        return callback({ type: "NOT_FOUND" });
      case 409:
        return callback({ type: "DUPLICATE" });
      default:
        return res.json().then((json) => {
          callback({ type: "ERROR_MESSAGE", message: errorMsg(json.message) });
        });
    }
  });

export const INITIAL_STATE = {
  accountId: "",
  postcode: "",
  disclaimer: false,
  title: "",
  firstName: "",
  lastName: "",
  address1: "",
  address2: "",
  dualFuel: false,
  guaranteedCredit: false,
  smartMeterInterest: false,
  declaration: false,
};

export const applicationMachine = Machine(
  {
    id: REGISTRATION_STATE,
    initial: WhdPreRegState.GATHER_ACCOUNT_DETAILS,
    context: INITIAL_STATE,
    states: {
      [WhdPreRegState.GATHER_ACCOUNT_DETAILS]: {
        on: {
          [StateAction.NEXT]: {
            target: WhdPreRegState.VERIFY_ACCOUNT_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
        },
      },
      [WhdPreRegState.VERIFY_ACCOUNT_DETAILS]: {
        invoke: {
          id: POST_ACCOUNT_DETAILS,
          src: (context) => (callback) => postOnSupplyCheck(context, callback),
          onError: WhdPreRegState.EXIT_ERROR_MESSAGE,
        },
        on: {
          OK: WhdPreRegState.GATHER_PERSONAL_DETAILS,
          NON_SCOTTISH_POSTCODE: WhdPreRegState.EXIT_NON_SCOTTISH_POSTCODE,
          DUPLICATE: WhdPreRegState.EXIT_DUPLICATE,
          NOT_FOUND: WhdPreRegState.EXIT_NOT_FOUND,
          NOT_ON_SUPPLY: WhdPreRegState.EXIT_NOT_ON_SUPPLY,
          MISMATCH: WhdPreRegState.EXIT_MISMATCH,
          ERROR_MESSAGE: {
            target: WhdPreRegState.EXIT_ERROR_MESSAGE,
            actions: assign({ message: (_, event) => event.message }),
          },
        },
      },
      [WhdPreRegState.GATHER_PERSONAL_DETAILS]: {
        on: {
          [StateAction.PREV]: {
            target: WhdPreRegState.GATHER_ACCOUNT_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
          [StateAction.NEXT]: {
            target: WhdPreRegState.VERIFY_ALL_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
        },
      },
      [WhdPreRegState.VERIFY_ALL_DETAILS]: {
        invoke: {
          id: POST_ALL_DETAILS,
          src: (context) => (callback) =>
            postApplicationDetails(context, callback),
          onError: WhdPreRegState.EXIT_ERROR_MESSAGE,
        },
        on: {
          CREATED: WhdPreRegState.END_SUCCESS,
          NON_SCOTTISH_POSTCODE: WhdPreRegState.EXIT_NON_SCOTTISH_POSTCODE,
          DUPLICATE: WhdPreRegState.EXIT_DUPLICATE,
          NOT_FOUND: WhdPreRegState.EXIT_NOT_FOUND,
          NOT_ON_SUPPLY: WhdPreRegState.EXIT_NOT_ON_SUPPLY,
          MISMATCH: WhdPreRegState.EXIT_MISMATCH,
          ERROR_MESSAGE: {
            target: WhdPreRegState.EXIT_ERROR_MESSAGE,
            actions: assign({ message: (_, event) => event.message }),
          },
        },
      },

      [WhdPreRegState.END_SUCCESS]: {},
      [WhdPreRegState.EXIT_NOT_FOUND]: {},
      [WhdPreRegState.EXIT_NOT_ON_SUPPLY]: {},
      [WhdPreRegState.EXIT_MISMATCH]: {},
      [WhdPreRegState.EXIT_DUPLICATE]: {},
      [WhdPreRegState.EXIT_POSTCODE_MISMATCH]: {},
      [WhdPreRegState.EXIT_ACCOUNT_NOT_ON_SUPPLY]: {},
      [WhdPreRegState.EXIT_NON_SCOTTISH_POSTCODE]: {},
      [WhdPreRegState.EXIT_ERROR_MESSAGE]: {},
    },
  },
  {
    actions: {
      [EventActions.PUT_DETAILS]: makeAssignObject(Object.keys(INITIAL_STATE)),
      [EventActions.RESET_FORM_STATE]: assign(INITIAL_STATE),
    },
  },
);
