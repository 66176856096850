import { useService } from "@xstate/react";
import { useForm } from "react-hook-form";
import { INITIAL_STATE } from "../whd-form-state";
import { InputRadioYesNo } from "../../inputComponents/InputRadio";
import React, { useState } from "react";
import { FormCustomerDetailsData } from "../formData/FormCustomerDetailsData";
import { TextFieldWithDescription } from "../../inputComponents/TextFieldWithDescription";
import {
  _handleBack,
  fieldReqError,
  handleInputChange,
  onSubmitWithState,
} from "../../util/reusableFunctions/util";

export const FormDisabilityCare = ({ stateService }: { stateService: any }) => {
  const [state, send]: any[] = useService(stateService);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data: any) => onSubmitWithState(send, state)(data);

  const [formData, setFormData] =
    useState<FormCustomerDetailsData>(INITIAL_STATE);

  const handleBack = () => _handleBack(state, formData, send);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bordered-div">
        <div className="steps-description">
          <p className="left">
            <TextFieldWithDescription
              value="Your current benefits"
              description="Disability and Care"
            />
          </p>
          <p className="right brand-midnight">Step 5 of 9</p>
        </div>

        <InputRadioYesNo
          required
          name="q15"
          question="Do you (or your partner) get a disability premium (all rates)?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q15 : ""}
        />

        <InputRadioYesNo
          required
          name="q18"
          question="Do you (or your partner) receive Industrial Injuries Benefit?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q18 : ""}
        />

        <InputRadioYesNo
          required
          name="q16"
          question="Do you (or your partner) get Severe Disablement Allowance (income support only)?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q16 : ""}
        />

        <InputRadioYesNo
          required
          name="q23"
          question="Do you (or your partner) get a Carers Allowance when caring for someone who resides at the same property?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q23 : ""}
        />

        <InputRadioYesNo
          required
          name="q24"
          question="Do you (or your partner) receive the War Disablement Pension?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q24 : ""}
        />
      </div>

      <input
        type="submit"
        value="< Back"
        className="back-button"
        onClick={handleBack}
      />
      <input type="submit" value="Submit >" className="submit-input" />
    </form>
  );
};
