import React from "react";
import uniqueId from "lodash/uniqueId";
import camelCase from "lodash/camelCase";

export const InputSelector = ({
  register,
  name,
  label,
  required,
  options,
  placeholder,
  errors,
  errorMessage,
  defaultValue,
  onChange,
}: {
  register: any;
  required?: boolean;
  name?: string;
  label: string;
  errors: any;
  errorMessage: string;
  options: string[];
  placeholder: string;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}) => {
  const _uniqueId = uniqueId();
  const camelName = camelCase(name || label);
  const _defaultValue = defaultValue || "";

  return (
    <div className="form-group">
      <b>
        <label htmlFor={_uniqueId}>{label}</label>
      </b>

      <select
        className={`form-control-input-custom${
          errors[camelName] ? " is-invalid" : ""
        }`}
        id={_uniqueId}
        ref={register({ required })}
        name={camelName}
        placeholder={placeholder}
        aria-describedby={`${_uniqueId}-feedback`}
        defaultValue={_defaultValue}
        onChange={onChange}
      >
        {options.map((option) => {
          const _camelName = camelCase(option);

          return (
            <option key={_camelName} value={_camelName}>
              {option}
            </option>
          );
        })}
      </select>
      {errors[camelName] && (
        <div id={`${_uniqueId}-feedback`} className="invalid-feedback">
          {errorMessage}
        </div>
      )}
    </div>
  );
};
