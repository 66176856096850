import React from "react";
import { PreRegApp } from "./whd-pre-reg/preRegApp";
import { OpenApp } from "./whd-form/openApp";
import { ClosedApp } from "./whd-form/closedApp";

const { REACT_APP_APPLICATIONS_STATUS } = process.env;

export const App = () => {
  switch (REACT_APP_APPLICATIONS_STATUS) {
    case "form":
      return <OpenApp />;
    case "form-closed":
      return <ClosedApp />;
    case "pre-reg":
      return <PreRegApp />;
    default:
      return (
        <div className="spinner-grow" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
  }
};
