import { headerComponent } from "./headerComponent";
import { legal } from "./legal";
import React from "react";

export const main = (
  element: JSX.Element,
  mainRef: React.MutableRefObject<null>,
  headerMessage: string
) => (
  <>
    <main ref={mainRef} className="container">
      {headerComponent(headerMessage)}
      {element}
      {legal}
    </main>
  </>
);
