import React from "react";

export const TextFieldWithDescription = ({
  value,
  description,
}: {
  value: string;
  description?: string;
}) => {
  return (
    <div className="form-group">
      <h3>{value}</h3>

      {description && (
        <h5 className="description brand-midnight">{description}</h5>
      )}
    </div>
  );
};
