import React, { ChangeEvent } from "react";
import uniqueId from "lodash/uniqueId";
import camelCase from "lodash/camelCase";
import startCase from "lodash/startCase";

export const InputRadio = ({
  register,
  name,
  question,
  required,
  options,
  defaultChoiceIndex,
}: {
  register: any;
  required?: boolean;
  name?: string;
  question: string;
  options: string[];
  defaultChoiceIndex?: number;
}) => {
  const _question = camelCase(name || question);
  const _options = options.map((option) => ({
    option,
    id: uniqueId(),
  }));

  return (
    <div className="form-group">
      <p>{question}</p>

      {_options.map(({ option, id }, index) => {
        const camelName = camelCase(option);

        return (
          <div key={camelName} className="radio-container">
            <label className="radio-option" htmlFor={id}>
              <input
                type="radio"
                id={id}
                defaultChecked={index === defaultChoiceIndex}
                name={_question}
                value={camelName}
                ref={register({ required })}
              />
              <span className="custom-radio"></span>
              {startCase(option)}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export const InputRadioWithHint = ({
  register,
  name,
  question,
  hint,
  required,
  options,
  defaultChoiceIndex,
  handler,
}: {
  register: any;
  required?: boolean;
  name?: string;
  question: string;
  hint: string;
  options: { label: string; value: string }[];
  defaultChoiceIndex?: number;
  handler: () => any;
}) => {
  const _uniqueId = uniqueId();
  const _question = camelCase(name || question);
  const _options = options.map((option) => ({
    option,
    id: uniqueId(),
  }));

  return (
    <div className="form-group">
      <b>{question}</b>

      {hint && (
        <small id={`${_uniqueId}-feedback`} className="form-text text-muted">
          {hint}
        </small>
      )}

      <div style={{ marginTop: 10 }} />

      {_options.map(({ option, id }, index) => {
        const camelName = camelCase(option.value);

        return (
          <div key={camelName} className="radio-container">
            <label className="radio-option" htmlFor={id}>
              <input
                type="radio"
                id={id}
                defaultChecked={index === defaultChoiceIndex}
                name={_question}
                value={camelName}
                ref={register({ required })}
                onChange={handler}
              />
              <span className="custom-radio"></span>
              {option.label}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export const InputRadioYesNo = ({
  register,
  question,
  required,
  name,
  defaultValue,
  errors,
  errorMessage,
  onChange,
}: {
  register: any;
  required?: boolean;
  question: string;
  name?: string;
  defaultValue?: string;
  errors?: any;
  errorMessage?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const options = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];
  return (
    <InputRadioLabel
      name={name}
      question={question}
      required={required}
      register={register}
      options={options}
      defaultValue={defaultValue}
      errors={errors}
      errorMessage={errorMessage}
      onChange={onChange}
    />
  );
};

export const InputRadioLabel = ({
  register,
  name,
  question,
  details,
  required,
  options,
  defaultValue,
  errors,
  errorMessage,
  isChecked,
  onChange,
}: {
  register: any;
  required?: boolean;
  name?: string;
  question: string;
  details?: string;
  options: { label: string; value: string }[];
  defaultValue?: string;
  errors?: any;
  errorMessage?: string;
  isChecked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const _question = camelCase(name || question);
  const _options = options.map((option) => ({
    option,
    id: uniqueId(),
    defaultChecked: option.value === defaultValue,
  }));
  const _isChecked = isChecked || false;

  return (
    <div className="form-group">
      <b>{question}</b>
      <p>{details}</p>

      <b>
        {_options.map(({ option, id, defaultChecked }, index) => {
          return (
            <div key={option.value} className="radio-container">
              <label className="radio-option" htmlFor={id}>
                <input
                  type="radio"
                  id={id}
                  defaultChecked={defaultChecked || _isChecked}
                  name={_question}
                  value={option.value}
                  ref={register({ required })}
                  onChange={onChange}
                />
                <span className="custom-radio"></span>
                {option.label}
              </label>
            </div>
          );
        })}
      </b>
      {errors && errors[_question] && (
        <div className="error-message">{errorMessage}</div>
      )}
    </div>
  );
};
