import get from "lodash/get";
import { assign } from "xstate";

export const StateAction = {
  NEXT: "NEXT",
  PREV: "PREV",
  JUMP: "JUMP",
};

export const EventActions = {
  PUT_DETAILS: "PUT_DETAILS",
  RESET_FORM_STATE: "RESET_FORM_STATE",
};

export const makeAssignObject = (list) => {
  const assignObj = {};

  list.forEach((i) => {
    assignObj[i] = (context, event) =>
      get(event, `${i}`) || get(context, `${i}`);
  });

  return assign(assignObj);
};

export const isTruthy = (val) => {
  const _val = `${val}`.toLowerCase();

  return _val === "yes" || _val === "true";
};
