import { useService } from "@xstate/react";
import { accountIdRegex, postcodeRegex, sanitiseData } from "../../util/common";
import { useForm } from "react-hook-form";
import { InputText } from "../../inputComponents/InputText";
import { InputCheckBox } from "../../inputComponents/InputCheckBox";
import React from "react";
import { StateAction } from "../../util/stateUtil";

export const FormAccount = ({ stateService }: { stateService: any }) => {
  const [state, send]: any[] = useService(stateService);

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data: any) => send(StateAction.NEXT, sanitiseData(data));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bordered-div">
        <div className="steps-description">
          <p className="left">
            <b>Your account</b>
          </p>
          <p className="right brand-midnight">Step 1 of 2</p>
        </div>

        <InputText
          label="Account Number"
          name="Account ID"
          register={register}
          errors={errors}
          required
          errorMessage="This must be a valid Account ID"
          hint="This is found on your monthly statement. If you’re a Pay As You Go customer, you can find your account number in your welcome pack. Alternatively, you can also find it on emails or letters from us."
          pattern={accountIdRegex}
          placeholder="eg. 12093810"
          width={35}
          defaultValue={state ? state.context.accountId : ""}
        />

        <InputText
          label="Postcode"
          register={register}
          errors={errors}
          required
          errorMessage="A valid UK post code is required, e.g G3 8YW"
          hint="This should be for the property that has energy supplied by us."
          pattern={postcodeRegex}
          placeholder="eg. G3 8YW"
          width={35}
          defaultValue={state ? state.context.postcode : ""}
        />

        <InputCheckBox
          required
          errorMessage="This field is required"
          label="disclaimer"
          question=""
          statement={[
            "Please tick this box to confirm OVO has your most up to date information before proceeding." +
              "Filling this in will not automatically update any account information." +
              " If you need to update your information, visit myOvo or here ",
            <a
              key="contact-us"
              className={"hyperlink-color"}
              href="https://company.ovo.com/contact-us/"
            >
              get in touch.
            </a>,
          ]}
          register={register}
          errors={errors}
          isChecked={state ? state.context.disclaimer : false}
        />
      </div>

      <input type="submit" value="Next >" className="submit-input" />
    </form>
  );
};
