import { StateAction } from "../stateUtil";
import { FormCustomerDetailsData } from "../../whd-form/formData/FormCustomerDetailsData";
import React, { ChangeEvent } from "react";
import { wrapper } from "../common";
import { DumbPageSomethingWentWrong } from "../../commonComponents/CommonDumbPages";

export const _handleBack = (
  state: any,
  formData: FormCustomerDetailsData,
  send: any,
) => {
  const stateContext = state.context;

  const payload = {
    accountId: stateContext.accountId,
    postcode: stateContext.postcode,
    title: formData.title,
    name: formData.name,
    surname: formData.surname,
    address1: formData.address1,
    address2: formData.address2,
    telephone: formData.telephone,
    email: formData.email,
    q1: formData.q1,
    q2: formData.q2,
    q3: formData.q3,
    q4: formData.q4,
    q5: formData.q5,
    q6: formData.q6,
    q7: formData.q7,
    q8: formData.q8,
    q9: formData.q9,
    q10: formData.q10,
    q11: formData.q11,
    q12: formData.q12,
    q13: formData.q13,
    q14: formData.q14,
    q15: formData.q15,
    q16: formData.q16,
    q17: formData.q17,
    q18: formData.q18,
    q19: formData.q19,
    q20: formData.q20,
    q21: formData.q21,
    q22: formData.q22,
    q23: formData.q23,
    q24: formData.q24,
    q25: formData.q25,
    q26: formData.q26,
    q27: formData.q27,
  };

  send(StateAction.PREV, payload);
};

export function _setFormData<T>(
  name: string,
  value: string,
  formData: T,
  setFormData: (data: T) => void,
) {
  setFormData({ ...formData, [name]: value });
}

export function handleInputChange<T>(
  formData: T,
  setFormData: (data: T) => void,
) {
  return (
    e: ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    return _setFormData(name, value, formData, setFormData);
  };
}

export function handleInputCheckChange<T>(
  formData: T,
  setFormData: (data: T) => void,
) {
  return (e: ChangeEvent<HTMLInputElement>) => {
    const { name, type, checked, value } = e.target;
    const _checked: string = checked ? "yes" : "no";
    const inputValue = type === "checkbox" ? _checked : value;
    _setFormData(name, inputValue, formData, setFormData);
  };
}

export const onSubmit =
  (send: (stateAction: string, obj: object) => void) => (data: object) => {
    send(StateAction.NEXT, data);
  };

export const onSubmitWithState =
  (send: (stateAction: string, obj: object) => void, state: any) =>
  (data: any) => {
    const stateContext = state.context;

    const prevState = {
      accountId: stateContext.accountId,
      postcode: stateContext.postcode,
      disclaimer: stateContext.disclaimer,
      title: stateContext.title,
      firstName: stateContext.firstName,
      lastName: stateContext.lastName,
      address1: stateContext.address1,
      address2: stateContext.address2,
      smartMeterInterest: stateContext.smartMeterInterest,
      declaration: stateContext.declaration,
    };

    const payload = { ...prevState, ...data };
    send(StateAction.NEXT, payload);
  };

export const fieldReqError: string = "This field is required";

export const errorMsg = (message?: string) =>
  message ? wrapper(message) : <DumbPageSomethingWentWrong />;

export const returnedError = (
  res: Response,
  callback: (arg0: { type: string; message: JSX.Element }) => void,
  unexpected: () => void,
) =>
  res
    .json()
    .then((json) => {
      console.log("Received json response: " + json.toString());
      if (json.message)
        callback({
          type: "ERROR_MESSAGE",
          message: errorMsg(json.message),
        });
      else unexpected();
    })
    .catch(() => unexpected());
