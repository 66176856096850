import { logo } from "../commonComponents/logo";
import React from "react";

export const ClosedApp = () => (
  <div>
    {logo}
    <p className="brand-midnight thank-footer">
      To register your interest please revisit this page from 03/06/24
    </p>
  </div>
);
