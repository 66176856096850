import { useService } from "@xstate/react";
import { useForm } from "react-hook-form";
import { INITIAL_STATE } from "../whd-form-state";
import { InputRadioYesNo } from "../../inputComponents/InputRadio";
import React, { useState } from "react";
import { FormCustomerDetailsData } from "../formData/FormCustomerDetailsData";
import { TextFieldWithDescription } from "../../inputComponents/TextFieldWithDescription";
import {
  _handleBack,
  fieldReqError,
  handleInputChange,
  onSubmitWithState,
} from "../../util/reusableFunctions/util";

export const FormIncomeWork = ({ stateService }: { stateService: any }) => {
  const [state, send]: any[] = useService(stateService);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data: any) => onSubmitWithState(send, state)(data);

  const [formData, setFormData] =
    useState<FormCustomerDetailsData>(INITIAL_STATE);

  const handleBack = () => _handleBack(state, formData, send);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bordered-div">
        <div className="steps-description">
          <p className="left">
            <TextFieldWithDescription
              value="Your current benefits"
              description="Income and Work"
            />
          </p>
          <p className="right brand-midnight">Step 4 of 9</p>
        </div>

        <InputRadioYesNo
          required
          name="q6"
          question="Do you (or your partner) get Universal Credit, are self-employed or not in work?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q6 : ""}
        />

        <InputRadioYesNo
          required
          name="q8"
          question="Do you (or your partner) get Universal Credit on an income of between 0 and £1,665 in at least one month during the period 01 April 2024 to 31 March 2025?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q8 : ""}
        />

        <InputRadioYesNo
          required
          name="q3"
          question="Do you (or your partner) get Income Support?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q3 : ""}
        />

        <InputRadioYesNo
          required
          name="q5"
          question="Do you (or your partner) get Income Related Employment and Support Allowance?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q5 : ""}
        />

        <InputRadioYesNo
          required
          name="q4"
          question="Do you (or your partner) get income based Jobseeker's Allowance?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q4 : ""}
        />

        <InputRadioYesNo
          required
          name="q11"
          question="Is the Gross Annual Household Income (before benefits, tax and deductions) less than £19,978?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q11 : ""}
        />

        <InputRadioYesNo
          required
          name="q12"
          question="Do you (or your partner) get Personal Independence Payment or Adult Disability Payment?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q12 : ""}
        />

        <InputRadioYesNo
          required
          name="q25"
          question="Do you (or your partner) get a limited capability for work element (with or without a work related activity element) as part of your Universal Credit?"
          register={register}
          errors={errors}
          errorMessage={fieldReqError}
          onChange={handleInputChange(formData, setFormData)}
          defaultValue={state ? state.context.q25 : ""}
        />
      </div>

      <input
        type="submit"
        value="< Back"
        className="back-button"
        onClick={handleBack}
      />
      <input type="submit" value="Submit >" className="submit-input" />
    </form>
  );
};
