import { assign, Machine } from "xstate";
import {
  EventActions,
  isTruthy,
  makeAssignObject,
  StateAction,
} from "../util/stateUtil";
import { errorMsg, returnedError } from "../util/reusableFunctions/util";

const {
  REACT_APP_API_URL_ON_SUPPLY,
  REACT_APP_API_URL_APPLICATION,
  REACT_APP_API_URL_TOKEN_VALIDATION,
} = process.env;
const APPLICATION_STATE = "applicationState";
const VERIFY_TOKEN_LINK = "verifyTokenLink";
const POST_ACCOUNT_DETAILS = "postAccountDetails";
const POST_ALL_DETAILS = "postAllDetails";

export const WhdFormState = {
  TOKEN_VERIFICATION_DETAILS: "TOKEN_VERIFICATION_DETAILS",
  GATHER_ACCOUNT_DETAILS: "GATHER_ACCOUNT_DETAILS",
  VERIFY_ACCOUNT_DETAILS: "VERIFY_ACCOUNT_DETAILS",
  GATHER_PERSONAL_DETAILS: "GATHER_PERSONAL_DETAILS",
  CHILD_BENEFITS_DETAILS: "CHILD_BENEFITS_DETAILS",
  INCOME_WORK_DETAILS: "INCOME_WORK_DETAILS",
  DISABILITY_CARE_DETAILS: "DISABILITY_CARE_DETAILS",
  HOUSING_DETAILS: "HOUSING_DETAILS",
  PENSION_DETAILS: "PENSION_DETAILS",
  GATHER_MAIN_ACCOUNT_HOLDER_DETAILS: "GATHER_MAIN_ACCOUNT_HOLDER_DETAILS",
  FINAL_DECLARATION: "FINAL_DECLARATION",
  VERIFY_ALL_DETAILS: "VERIFY_ALL_DETAILS",
  END_SUCCESS: "END_SUCCESS",
  EXIT_ERROR_MESSAGE: "EXIT_ERROR_MESSAGE",
  EXIT_UNEXPECTED: "EXIT_UNEXPECTED",
  EXIT_ACCOUNT_NOT_ON_SUPPLY: "EXIT_ACCOUNT_NOT_ON_SUPPLY",
  EXIT_NON_SCOTTISH_POSTCODE: "EXIT_NON_SCOTTISH_POSTCODE",
  EXIT_TOKEN_NOT_VALID: "EXIT_TOKEN_NOT_VALID",
};

const verifyToken = (callback) => {
  const token = new URL(window.location.href).searchParams.get("token");

  if (token) {
    return fetch(REACT_APP_API_URL_TOKEN_VALIDATION, {
      method: "POST",
      body: JSON.stringify({ token: token }),
    }).then((res) => {
      if (res.status === 200) {
        return res.json().then((json) => {
          if (json.tokenStatus !== "Valid")
            return callback({ type: "INVALID_TOKEN" });
          else
            return callback({
              type: "VALID_TOKEN",
              accountId: json.accountId,
              token: json.token,
            });
        });
      } else return callback({ type: "INVALID_TOKEN" });
    });
  } else return callback({ type: "NO_TOKEN" });
};

const postOnSupplyCheck = ({ accountId, postcode }, callback) => {
  const unexpected = () => {
    callback({ type: "UNEXPECTED" });
  };

  fetch(REACT_APP_API_URL_ON_SUPPLY, {
    method: "POST",
    body: JSON.stringify({ accountId: accountId.trim(), postcode }),
  })
    .then((res) => {
      switch (res.status) {
        case 200:
          return callback({ type: "OK" });
        case 230:
          return callback({ type: "ACCOUNT_NOT_ON_SUPPLY" });
        case 240:
          return callback({ type: "NON_SCOTTISH_POSTCODE" });
        default:
          return returnedError(res, callback, unexpected);
      }
    })
    .catch(() => unexpected());
};

const postApplicationDetails = (context, callback) =>
  fetch(REACT_APP_API_URL_APPLICATION, {
    method: "POST",
    headers: {
      "X-APP-LINK-TOKEN": context.token,
    },
    body: JSON.stringify({
      title: context.title,
      name: context.name,
      surname: context.surname,
      accountId: context.accountId.trim(),
      email: context.email,
      address1: context.address1,
      address2: context.address2,
      postcode: context.postcode,
      telephone: context.telephone,
      payElectricAccount:
        context.payElectricAccount.toLowerCase() ===
        INITIAL_STATE.payElectricAccount.toLowerCase(),
      q1: isTruthy(context.q1),
      q2: isTruthy(context.q2),
      q3: isTruthy(context.q3),
      q4: isTruthy(context.q4),
      q5: isTruthy(context.q5),
      q6: isTruthy(context.q6),
      q7: isTruthy(context.q7),
      q8: isTruthy(context.q8),
      q9: isTruthy(context.q9),
      q10: isTruthy(context.q10),
      q11: isTruthy(context.q11),
      q12: isTruthy(context.q12),
      q13: isTruthy(context.q13),
      q14: isTruthy(context.q14),
      q15: isTruthy(context.q15),
      q16: isTruthy(context.q16),
      q17: isTruthy(context.q17),
      q18: isTruthy(context.q18),
      q19: isTruthy(context.q19),
      q20: isTruthy(context.q20),
      q21: isTruthy(context.q21),
      q22: isTruthy(context.q22),
      q23: isTruthy(context.q23),
      q24: isTruthy(context.q24),
      q25: isTruthy(context.q25),
      q26: isTruthy(context.q26),
      q27: isTruthy(context.q27),
      declaration1: isTruthy(context.declaration1),
      declaration2: isTruthy(context.declaration2),
      declaration3: isTruthy(context.declaration3),
      onBehalfAccountHolder: isTruthy(context.onBehalfAccountHolder),
      onBehalfAccountHolderName: isTruthy(context.onBehalfAccountHolder)
        ? context.onBehalfAccountHolderName
        : null,
      onBehalfAccountHolderRelationship: isTruthy(context.onBehalfAccountHolder)
        ? context.onBehalfAccountHolderRelationship
        : null,
      isAccountHolderSameAsBenefitClaimant: isTruthy(
        context.isAccountHolderSameAsBenefitClaimant,
      ),
      benefitClaimantFirstName: isTruthy(
        context.isAccountHolderSameAsBenefitClaimant,
      )
        ? null
        : context.benefitClaimantFirstName,
      benefitClaimantSurname: isTruthy(
        context.isAccountHolderSameAsBenefitClaimant,
      )
        ? null
        : context.benefitClaimantSurname,
      benefitClaimantDob: isTruthy(context.isAccountHolderSameAsBenefitClaimant)
        ? null
        : context.benefitClaimantDob,
    }),
  }).then((res) => {
    switch (res.status) {
      case 201:
        return callback({ type: "CREATED" });
      default:
        return res.json().then((json) => {
          callback({ type: "ERROR_MESSAGE", message: errorMsg(json.message) });
        });
    }
  });

export const INITIAL_STATE = {
  accountId: "",
  title: "",
  name: "",
  surname: "",
  email: "",
  address1: "",
  address2: "",
  postcode: "",
  telephone: "",
  payElectricAccount: "electricity",
  q1: "",
  q2: "",
  q3: "",
  q4: "",
  q5: "",
  q6: "",
  q7: "",
  q8: "",
  q9: "",
  q10: "",
  q11: "",
  q12: "",
  q13: "",
  q14: "",
  q15: "",
  q16: "",
  q17: "",
  q18: "",
  q19: "",
  q20: "",
  q21: "",
  q22: "",
  q23: "",
  q24: "",
  q25: "",
  q26: "",
  q27: "",
  declaration1: false,
  declaration2: false,
  declaration3: false,
  token: "",
  message: "",
  onBehalfAccountHolder: "no",
  onBehalfAccountHolderName: "",
  onBehalfAccountHolderRelationship: "",
  isAccountHolderSameAsBenefitClaimant: "yes",
  benefitClaimantFirstName: "",
  benefitClaimantSurname: "",
  benefitClaimantDob: "",
};

export const applicationMachine = Machine(
  {
    id: APPLICATION_STATE,
    initial: WhdFormState.TOKEN_VERIFICATION_DETAILS,
    context: INITIAL_STATE,
    states: {
      [WhdFormState.TOKEN_VERIFICATION_DETAILS]: {
        invoke: {
          id: VERIFY_TOKEN_LINK,
          src: () => (callback) => verifyToken(callback),
        },
        on: {
          NO_TOKEN: WhdFormState.GATHER_ACCOUNT_DETAILS,
          INVALID_TOKEN: WhdFormState.EXIT_TOKEN_NOT_VALID,
          VALID_TOKEN: {
            target: WhdFormState.GATHER_PERSONAL_DETAILS,
            actions: assign({
              accountId: (_, event) => event.accountId,
              token: (_, event) => event.token,
            }),
          },
        },
      },

      [WhdFormState.GATHER_ACCOUNT_DETAILS]: {
        on: {
          [StateAction.NEXT]: {
            target: WhdFormState.VERIFY_ACCOUNT_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
        },
      },

      [WhdFormState.VERIFY_ACCOUNT_DETAILS]: {
        invoke: {
          id: POST_ACCOUNT_DETAILS,
          src: (context) => (callback) => postOnSupplyCheck(context, callback),
          onError: WhdFormState.EXIT_ERROR_MESSAGE,
        },
        on: {
          OK: WhdFormState.GATHER_PERSONAL_DETAILS,
          ACCOUNT_NOT_ON_SUPPLY: WhdFormState.EXIT_ACCOUNT_NOT_ON_SUPPLY,
          NON_SCOTTISH_POSTCODE: WhdFormState.EXIT_NON_SCOTTISH_POSTCODE,
          UNEXPECTED: WhdFormState.EXIT_UNEXPECTED,
          ERROR_MESSAGE: {
            target: WhdFormState.EXIT_ERROR_MESSAGE,
            actions: assign({ message: (_, event) => event.message }),
          },
        },
      },

      [WhdFormState.GATHER_PERSONAL_DETAILS]: {
        on: {
          [StateAction.PREV]: {
            target: WhdFormState.GATHER_ACCOUNT_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
          [StateAction.NEXT]: {
            target: WhdFormState.CHILD_BENEFITS_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
        },
      },

      [WhdFormState.CHILD_BENEFITS_DETAILS]: {
        on: {
          [StateAction.PREV]: {
            target: WhdFormState.GATHER_PERSONAL_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
          [StateAction.NEXT]: {
            target: WhdFormState.INCOME_WORK_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
        },
      },

      [WhdFormState.INCOME_WORK_DETAILS]: {
        on: {
          [StateAction.PREV]: {
            target: WhdFormState.CHILD_BENEFITS_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
          [StateAction.NEXT]: {
            target: WhdFormState.DISABILITY_CARE_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
        },
      },

      [WhdFormState.DISABILITY_CARE_DETAILS]: {
        on: {
          [StateAction.PREV]: {
            target: WhdFormState.INCOME_WORK_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
          [StateAction.NEXT]: {
            target: WhdFormState.HOUSING_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
        },
      },

      [WhdFormState.HOUSING_DETAILS]: {
        on: {
          [StateAction.PREV]: {
            target: WhdFormState.DISABILITY_CARE_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
          [StateAction.NEXT]: {
            target: WhdFormState.PENSION_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
        },
      },

      [WhdFormState.PENSION_DETAILS]: {
        on: {
          [StateAction.PREV]: {
            target: WhdFormState.HOUSING_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
          [StateAction.NEXT]: {
            target: WhdFormState.GATHER_MAIN_ACCOUNT_HOLDER_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
        },
      },

      [WhdFormState.GATHER_MAIN_ACCOUNT_HOLDER_DETAILS]: {
        on: {
          [StateAction.PREV]: {
            target: WhdFormState.PENSION_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
          [StateAction.NEXT]: {
            target: WhdFormState.FINAL_DECLARATION,
            actions: [EventActions.PUT_DETAILS],
          },
        },
      },

      [WhdFormState.FINAL_DECLARATION]: {
        on: {
          [StateAction.PREV]: {
            target: WhdFormState.GATHER_MAIN_ACCOUNT_HOLDER_DETAILS,
            actions: [EventActions.PUT_DETAILS],
          },
          [StateAction.NEXT]: WhdFormState.VERIFY_ALL_DETAILS,
        },
      },

      [WhdFormState.VERIFY_ALL_DETAILS]: {
        invoke: {
          id: POST_ALL_DETAILS,
          src: (context) => (callback) =>
            postApplicationDetails(context, callback),
          onError: WhdFormState.EXIT_ERROR_MESSAGE,
        },
        on: {
          CREATED: WhdFormState.END_SUCCESS,
          NON_SCOTTISH_POSTCODE: WhdFormState.EXIT_NON_SCOTTISH_POSTCODE,
          UNEXPECTED: WhdFormState.EXIT_UNEXPECTED,
          ERROR_MESSAGE: {
            target: WhdFormState.EXIT_ERROR_MESSAGE,
            actions: assign({ message: (_, event) => event.message }),
          },
        },
      },

      [WhdFormState.END_SUCCESS]: {},
      [WhdFormState.EXIT_ERROR_MESSAGE]: {},
      [WhdFormState.EXIT_UNEXPECTED]: {},
      [WhdFormState.EXIT_ACCOUNT_NOT_ON_SUPPLY]: {},
      [WhdFormState.EXIT_NON_SCOTTISH_POSTCODE]: {},
      [WhdFormState.EXIT_TOKEN_NOT_VALID]: {},
    },
  },
  {
    actions: {
      [EventActions.PUT_DETAILS]: makeAssignObject(Object.keys(INITIAL_STATE)),
      [EventActions.RESET_FORM_STATE]: assign(INITIAL_STATE),
    },
  },
);
