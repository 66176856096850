import React from "react";

export const logo: JSX.Element = (
  <div className="logo-title">
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      aria-labelledby="logo-title"
    >
      <title id="logo-title">OVO logo</title>
      <path
        fill="#0A9828"
        d="M14.316 16.104c-1.837-1.34-4.186-2.084-6.733-2.084-2.262 0-4.364.589-6.1 1.66L0 10.143 37.861 0l3.767 14.063a12.37 12.37 0 0 0-1.054-.045c-2.562 0-4.924.752-6.771 2.109l.697-1.803h-7.311l-2.562 8.771c-.278.95-.565 1.982-.565 1.982l-.557-1.982-2.56-8.77h-7.316l.687 1.779Zm22.654 8.023c0-2.05 1.382-3.76 3.606-3.76 1.711 0 2.919 1.013 3.387 2.414l.127.472c.058.28.092.57.092.873 0 2.2-1.484 3.71-3.606 3.71s-3.606-1.51-3.606-3.71Zm-25.781 0c0-2.05-1.384-3.76-3.606-3.76-2.224 0-3.61 1.71-3.61 3.76 0 2.2 1.489 3.71 3.61 3.71 2.122 0 3.606-1.51 3.606-3.71Zm18.866 1.69c.835 5.104 5.232 8.101 10.521 8.101 2.209 0 4.265-.52 5.972-1.5l1.456 5.44L10.143 48 6.357 33.865c.401.036.814.055 1.226.055 5.27 0 9.659-2.98 10.515-8.057l3 7.753h5.934l3.023-7.8Z"
      ></path>
    </svg>
    <h1 className="brand-midnight logo-size">
      <b>Warm Home Discount</b>
    </h1>
  </div>
);
