import React from "react";

const {
  REACT_APP_URL_TERMS_AND_CONDITIONS,
  REACT_APP_PRODUCT,
  REACT_APP_URL_WARM_HOME_DISCOUNT_SCHEME_INFO,
} = process.env;

export const DumbPageSuccess = () => (
  <div className="thank-footer">
    <h1>Thanks!</h1>
    <p>We have your application form, so you're all done for now.</p>
    <p>
      It might take us up to 5 days to review it, but we’ll be in touch as soon
      as possible. If you’re successful, you might need to send us some
      supporting documents, but we’ll give you plenty of notice. In the
      meantime, you can check the{" "}
      <a href={REACT_APP_URL_TERMS_AND_CONDITIONS}>
        terms and conditions here.
      </a>
    </p>
    <p>
      Thanks,
      <br />
      {REACT_APP_PRODUCT}
    </p>
  </div>
);

export const DumbPageExitAccountNotOnSupply = () => (
  <div className="thank-footer">
    Sorry - sadly we only supply gas to this account number, which means you
    will not be able to register your interest for the Warm Home Discount with
    us. Please refer your Warm Home Discount query to your electricity supplier,
    or try again with your OVO/BST electricity account number. (
    <a href={REACT_APP_URL_WARM_HOME_DISCOUNT_SCHEME_INFO} target="_top">
      Here’s more about who can get it.
    </a>
    )
  </div>
);

export const DumbPageExitNonScottishPostcode = () => (
  <div className="thank-footer">
    Applications for the Warm Home Discount are only open to customers in
    Scotland. Eligible customers in England and Wales will be paid
    automatically. To find out more, head{" "}
    <a href={REACT_APP_URL_WARM_HOME_DISCOUNT_SCHEME_INFO} target="_top">
      here
    </a>
    .
  </div>
);

export const DumbPageExitTokenNotValid = () => (
  <div className="thank-footer">
    Sorry – this link has expired or you already applied. Please contact us for
    further information. (
    <a href={REACT_APP_URL_WARM_HOME_DISCOUNT_SCHEME_INFO} target="_top">
      Here’s more about who can get it.
    </a>
    )
  </div>
);
